// import en from "./en.json";
// import de from "./de.json";

import VueI18n from "vue-i18n";
import Vue from "vue";
// import store from "../store";

Vue.use(VueI18n);

import en from "../translations/en.json";
import de from "../translations/de.json";

export const languages = {
  gb: en,
  en: en,
  de: de,
  at: de,
};
export const defaultLocale = "gb";
const messages = Object.assign(languages);

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages,
});

export default i18n;
